import React from "react";
import Mkc from "../images/home/mkc.png";
import modularkit from "../images/home/modularkit.png";
import mk1 from "../images/home/mk1.png";
import mk2 from "../images/home/mk2.png";
import mk3 from "../images/home/mk3.png";
import mk4 from "../images/home/mk4.png";
import mk5 from "../images/home/mk5.png";
import Servicecomponent from "../components/Servicecomponent";
import HeadData from "../components/Headdata";

const Modularkitchen = () => {
  const ModularImageData = [
    {
      image: mk1,
    },
    {
      image: mk2,
    },
    {
      image: mk3,
    },
    {
      image: mk4,
    },
    {
      image: mk5,
    },
  ];

  const ModularData = {
    coverimage: Mkc,
    subtitle: "Best Modular Kitchen Chennai",
    image: modularkit,
    title: "Best Modular Kitchen Chennai",
    para1:
      "If this is your first time getting into the world of modular kitchens, you might be confused with all the technical stuff. What goes where? Which design element would suit your kitchen? How would the overall ambiance be once you get the design right? These are the questions you need answers to and Splash Interiors has got that for you! Our team can design the best Modular Kitchen Chennai that looks good and also give you enhanced utility. We focus on improving the design of your kitchen from every aspect. Every project that we worked on helped us accumulate a plethora of skills and creativity needed in this field. We firmly believe that to produce the desired result, both technical and creative fluency is required. Here is what we can do for you:",
    para2: "",
    li1: "Understand your requirements and plan accordingly",
    li2: "Use the best of our talents to transform our ideas into tangible results",
    li3: "Ensure quality throughout the process",
    li4: "Effectively use design elements to bring out the best look and utility to your kitchen",
    para5:
      "The trend of setting up a stylish kitchen is high in urban areas because of the limited space offered by houses or apartments. Thus, modular kitchen Chennai can be your saviour in building a kitchen that meets all your purposes while improving your functionality. For example, if you are uncomfortable with the lower cabinets as you cannot bend down because of knee pain or have older parents at home, then you can customize your kitchen by having an upper cabinet.",
    para6:
      "Through years of industry experience, Splash Interiors assists in planning and developing the kitchen as per client customization. Therefore, we offer our clients complete guidance and come up with exciting and unique kitchen layout ideas so that you can pick the one which works for you! We also provide a modular kitchen Chennai with extra functionalities like a wall-mounted breakfast counter so you can have more than a kitchen space.",
    para7:
      "Our team knows the perfect placement for all the kitchen cabinets, shelves, countertops, and other modular kitchen elements to enhance the look of your kitchen. We plan and develop an appropriate design that will match the feel that you want to bring to the place. In recent times, the trend of modular kitchen has really picked up among people as they have discovered the endless benefits that they can get out of it. We have everything lined up perfectly for our customers. Our team strives to achieve accuracy in every Modular Kitchen Chennai project, and we are proud to have met the expectations of the customers in all of them.",
    para8:
      "Our team of talented interior designers will work on getting the color palette and the overall design right. A kitchen is always a special place in a home and it needs to offer ultimate functionality. We know where all the cabinets must go and the different dimensions of the shelves. Splash Interiors optimizes your kitchen space to make it spacious and look great visually. We give our best output no matter the nature of the project. Our team guarantees a neat-looking kitchen for you. Here are some of the layouts available in a modular kitchen design.",
    downhead: "Find out numerous choices of modern kitchens",
  };

  const headingsData = [
    {
      head: "1. Modular Kitchen with Acrylic cabinets",
      para: "This Modular Kitchen Chennai design is highly preferred by every customer as they can find everything with a structured arrangement like Kitchen cabinets, trolleys and tabletops which are arrayed along one wall within a single layout and these are specifically applicable for small spaces and it is available in various specifications. Acrylic Finish kitchen can give perfect outcome for our Kitchen cabinets, as it provides brightness and glossy-look. The main advantage of having Acrylic Finish is mainly for its easy maintenance, exceptional finishing, outlast durability and scratch resistance.",
    },
    {
      head: "2. L-Shaped Modular Kitchen",
      para: "It differs from other types of kitchen cabinets as it has its uniqueness of having the most adaptable layout, but this layout does not rely on one wall. So here we can have plenty of trolleys, drawers, storage space and countertop as it flawlessly gives us airy spacious feel and above all increases the overall appearance of your kitchen. L-shaped kitchen type is one of the modern kitchen types that gives enough space. It allows the kitchen items to be kept in the right angled design and gives you a spacious area in the center. Our Interior Decorators In Chennai will make it perfect by installing short or long right angled designs that suit your kitchen space.",
    },
    {
      head: "3. U-Shaped Modular Kitchen",
      para: "Well, this one particular type of Modular Kitchen Chennai layout has many advantages. If your kitchen space is large enough, then this is the ideal choice for you. As this specific kitchen format allows one to have an excess amount of storage space, trolleys than any other shaped kitchens and cooking, preparation will be at ease as it has vast space and even a small eating area can be designed at one end. And you don’t have to worry about cleaning and maintenance because this kitchen is adjoined with three sidewalls.",
    },
    {
      head: "4. Parallel Modular Kitchen:",
      para: "We at Splash Interiors are guaranteed for this perfect parallel Modular Kitchen Chennai as we have our best Interior designers to look out for the perfection. Here compared with other Modular kitchens this fit easily in a lengthy space and working in this parallel kitchen makes people feel more comfortable. As it has parallel countertops and so that work can be easily split at long working areas will look like facing each other.",
    },
    {
      head: "5. Island Modular Kitchen:",
      para: "In this modernized world this type of kitchen is highly in demand by many customers and as per their request we designed many Island Modular kitchen which can be viewed at our site gallery, because of its modern looks and elegant type and this can be adapted to any surrounding and size, which is why the demand is more for Island Modular Kitchen. Here many people think it suits a luxury kitchen, but the thing is it makes your kitchen look luxurious. More than its looks, it is safe, you can identify the kitchen appliances effortlessly and also you can keep an eye on your kids in this large spacious island modular kitchen. Our Modular Kitchen & Home Interior Designers In Chennai will make your kitchen more classy with this modern design.",
    },
    {
      head: "",
      para: "Whatever be the style of modular kitchen design you choose, we make sure that our professional Interior Designers In Chennai customize those designs for you and also add a bit of extra style that adds to the overall elegance of your beautiful kitchen. We make sure each and every detail is in the place for a perfectly stunning modular kitchen.",
    },
  ];
  return (
    <div className="Modularkitchen">
      <Servicecomponent Data={ModularData} ImageData={ModularImageData} />
      <HeadData headData={headingsData} />
    </div>
  );
};
export default Modularkitchen;
