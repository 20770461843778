import { useState } from "react";
import BackDrop from "./BackDrop";
import classnames from "classnames";
import "../css/navigationBar.scss";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { IoIosMenu, IoMdClose } from "react-icons/io";
import NavList from "./NavList";
import Card2 from "../components/Card2";

import logo from "../images/home/splashIcon.svg";
const NavigationBar = () => {
  const { width } = useWindowDimensions();
  const [showMenu, setShowMenu] = useState(true);

  const mobileView = width < 1000;

  // console.log(width, mobileView);
  const onClickHandler = () => {
    if (mobileView) {
      setShowMenu((prevState) => {
        return !prevState;
      });
    }
  };

  return (
    <nav className="nav">
      <Card2>
        <div
          className={classnames("nav_container", { mobileView: mobileView })}
        >
          <div className="nav_container_left">
            <img src={logo} alt="" />

            {/* <div className="logo_container">
          </div> */}
            {/* <div className="brand_name hideVisibility">Valleyafad</div> */}
          </div>
          <div className="nav_container_right">
            {!mobileView && <NavList toToggle={onClickHandler} from="header" />}
            {mobileView && (
              <button onClick={onClickHandler}>
                {showMenu && (
                  <IoIosMenu background="#1E1E1E" color="#f00;" size={40} />
                )}
                {!showMenu && (
                  <IoMdClose background="#1E1E1E" color="#1E1E1E" size={40} />
                )}
              </button>
            )}
          </div>
        </div>
      </Card2>

      {mobileView && !showMenu && (
        <div
          className={classnames("mobileViewContainer", {
            hideMenuItems: showMenu,
          })}
        >
          <BackDrop on={!showMenu}>
            <div className="cover_page">
              <NavList toToggle={onClickHandler} from="header" />
            </div>
          </BackDrop>
        </div>
      )}
    </nav>
  );
};

export default NavigationBar;
