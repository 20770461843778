import React, { useRef, useCallback, useState, useEffect } from "react";
import InputTextField from "../components/InputTextField";
import "../css/inputTextField.scss";
import "../css/textAreaField.scss";
import TextAreaField from "../components/textAreaField";
import "../css/form.scss";
import callback from "../images/home/callback.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Form = () => {
  useEffect(() => {
    setTimeout(() => {
      AOS.refresh();
    }, 1000);
  }, []);
  const error_message = {
    firstName: "",
    phonenumber: "",
    emailId: "",
    query: "",
  };

  const resetRefs = {
    firstName: useRef(null),
    phonenumber: useRef(null),
    emailId: useRef(null),
    query: useRef(null),
  };

  const [inputData, setInputData] = useState({
    firstName: "",
    phonenumber: "",
    emailId: "",
    query: "",
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    fullName: true,
    emailId: true,
    complaintDoctorName: true,
  });
  const alwaysValidated = (value) => {
    return true;
  };
  const noCharValidateCheck = (charValue) => {
    return true;
  };
  const storeData = useCallback((fieldName, value, validityStatus) => {
    setInputData((prevState) => {
      // console.log(prevState);
      return { ...prevState, [fieldName]: value };
    });
    setInputDataValidity((prevState) => ({
      ...prevState,
      [fieldName]: validityStatus,
    }));
  }, []);

  //  const FORMSPARK_ACTION_URL = "https://submit-form.com/u4xlocLq";
  const onSubmit = async (e) => {
    e.preventDefault();
    //    console.log(formdata);
    // if (isVerified != "") {
    // console.log(formData);
    // await fetch(FORMSPARK_ACTION_URL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     firstname: inputData.firstName,
    //     lastname: inputData.lastName,
    //     email: inputData.emailId,
    //     message: inputData.query,
    //   }),
    // });
    // alert("Form submitted");
    // console.log("Form submitted successfully!");

    // Object.keys(resetRefs).forEach((value) => {
    //   resetRefs[value].current.reset_data();
    // });
  };
  return (
    <div class="form">
      <div class="top-contactForm">
        <div
          class="topright"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div class="top-title">Request a Call Back </div>
          <form netlify>
            <div class="firstNames">
              <InputTextField
                ref={resetRefs["firstName"]}
                text="First Name"
                containerClassName="colSpan1"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["firstName"]}
                storeData={storeData.bind(null, "firstName")}
                placeholder="Name*"
                superText=""
              />

              <InputTextField
                ref={resetRefs["phonenumber"]}
                text="phonenumber"
                containerClassName="colSpan1"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["lastName"]}
                storeData={storeData.bind(null, "lastName")}
                placeholder="Phone no"
                superText=""
              />
            </div>
            <div class="emailField">
              <InputTextField
                ref={resetRefs["emailId"]}
                text="emailId"
                containerClassName="colSpan1"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["emailId"]}
                storeData={storeData.bind(null, "emailId")}
                placeholder="Email*"
                superText=""
              />
            </div>

            <div class="textareafield">
              <TextAreaField
                ref={resetRefs["query"]}
                text="query"
                containerClassName="colSpan1"
                validation={alwaysValidated}
                charValidate={noCharValidateCheck}
                error_message={error_message["query"]}
                storeData={storeData.bind(null, "query")}
                placeholder="Message"
                superText=""
              />
            </div>
            <div class="cont-but">
              <button className="cancelButton">Cancel</button>
              <button
                className="submitButton"
                // onClick={onSubmit}
                type="submit"
              >
                Submit now
              </button>
            </div>
          </form>
        </div>
        <div
          class="topleft"
          data-aos="fade"
          data-aos-duration="1500"
          data-aos-delay="300"
        >
          <img src={callback} alt="app" className="support-image" />
        </div>
      </div>
    </div>
  );
};
export default Form;
