import { NavLink } from "react-router-dom";
import "../css/navlist.scss";
import { useState } from "react";
import down from "../images/home/down.svg";
const NavList = ({ toToggle, from }) => {
  const onNavigate = () => {
    if (from === "header") {
      toToggle();
    }
  };

  //  const [showdropMenu, setshowdropMenu] = useState(true);
  //   const onClickHandler = () => {
  //     if (mobileView) {
  //       setshowdropMenu((prevState) => {
  //         return !prevState;
  //       });
  //     }
  //   };
  return (
    <>
      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/home"
        className="elements"
      >
        Home
      </NavLink>
      {/* <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/doctor"
        className="elements"
      >
        Doctor
      </NavLink> */}
      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/AboutUs"
        className="elements"
      >
        About Us
      </NavLink>

      <div class="dropdown">
        <div class="other-container">
          {/* <NavLink
            activeClassName="active_link"
            onClick={onNavigate}
            to=""
            className="elements"
          > */}
          <div class="elements">Services</div>
          {/* </NavLink> */}
          <img src={down} alt="app" className="down" />
        </div>
        {/* <img src={dropdown} alt="down" /> */}
        <div class="dropdown-content">
          <NavLink
            activeClassName="active_link"
            onClick={onNavigate}
            to="/CommercialInteriorDesign"
            className="dropdownelement"
          >
            Commercial Interior Design
          </NavLink>
          <NavLink
            activeClassName="active_link"
            onClick={onNavigate}
            to="/ResidentialInteriorDesign"
            className="dropdownelement"
          >
            Residential Interior Design
          </NavLink>
          <NavLink
            activeClassName="active_link"
            onClick={onNavigate}
            to="/ModularKitchenChennai"
            className="dropdownelement"
          >
            Modular Kitchen Chennai
          </NavLink>
          <NavLink
            activeClassName="active_link"
            onClick={onNavigate}
            to="/Industrialdesign"
            className="dropdownelement"
          >
            Industrial design
          </NavLink>
        </div>
      </div>

      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/Gallery"
        className="elements"
      >
        Gallery
      </NavLink>
      {/* 
      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/contactUs"
        className="elements"
      >
        Pricing
      </NavLink> */}

      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/Contact"
        className="elements"
      >
        Contact
      </NavLink>

      <NavLink
        activeClassName="active_link"
        onClick={onNavigate}
        to="/Blog"
        className="elements"
      >
        Blog
      </NavLink>
    </>
  );
};

export default NavList;
