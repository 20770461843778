import React from "react";
import industrial from "../images/service/industrial.png";
import id1 from "../images/service/id1.png";
import id2 from "../images/service/id2.png";
import id3 from "../images/service/id3.png";
import id4 from "../images/service/id4.png";
import id5 from "../images/service/id5.png";
import Servicecomponent from "../components/Servicecomponent";
import dtcover from "../images/home/dtc.png";

const IndustrialDesign = () => {
  const IdImageData = [
    {
      image: id1,
    },
    {
      image: id2,
    },
    {
      image: id3,
    },
    {
      image: id4,
    },
    {
      image: id5,
    },
  ];

  const IdData = {
    coverimage: dtcover,
    subtitle: "Industrial design",
    image: industrial,
    title: "Industrial design",
    para1:
      "Splash Interiors is a One Step Design Solutions for the Industrial design in Chennai. Our group is devoted to offer new Industrial Designs. According to the designer there are no rules related to interior designing. Splash Interiors gets the insights from the experts of Interior Design process. We create contemporary models by incorporating rustic and new industrial elements. Our artistic use of space includes strategy that rule out all the issues, without disregarding personal preferences and surroundings. Designing aspects involved in our Industrial Design,",
    li1: "Being thoughtful on Space planning and how people use their spaces",
    li2: "Goals set to create efficiency",
    li3: "Take a global approach",
    li4: "Creating and implementing the vision",
    para5:
      "We offer excellent services with respect to Industrial Design. The Modern industrial design imparts courage and is about exhibiting all that lies beneath to achieve raw, edgy style. Industrial style hinges on minimalism and usability using simple materials and few colors. We help you to learn how to achieve a mechanical, yet cosy industrial design elements that will alter a drab pad into the ‘loft’ of your lucid dream.",
    para6:
      "Our designers sketch out the ideas and create a pattern for the design. We focus on creating exquisite, pleasant and environmentally friendly spaces. Computer software’s are being used to create different models of virtual designs. The resources are analyzed by the Industrial Designers in Chennai for producing the end result.",
    para7:
      "Industrial designing cuts down the company’s unnecessary cost thus making the company to last longer. If the goal of a design tends to be simple and modern, we choose to add something a little quickly, which we think makes the room a little more interesting. Read the room and assess sightlines from different viewpoints.",
    para8:
      "Splash Interiors is a unique and highly personalized in Industrial design which produces best designs in the industry. We make the space more inviting and comfortable using flaccid furnishings and with robust colors. Industrial or commercial-instigated interior design reveals the inward process of a building – its architecture, materials and utilities. Splash Interiors, an Interior designing company represents the customers in achieving better aesthetics, safety and comfort.",
    downhead: "",
  };

  return (
    <div class="Id">
      <Servicecomponent Data={IdData} ImageData={IdImageData} />
    </div>
  );
};
export default IndustrialDesign;
