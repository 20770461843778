// ChatComponent.js
import React, { useState, useEffect } from "react";
import "../css/chatcomponent.scss"; // Import your CSS file for styling
import cross from "../images/home/x.svg";
// import dot from "../images/home/dot.svg";
import chat from "../images/blog/chaticon.svg";
import x from "../images/home/x.svg";
import send from "../images/blog/send.svg";
import Chatbot from "react-chatbot-kit";

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isChatOpen, setIsChatOpen] = useState(false);
  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleSendMessage = async () => {
    if (userInput.trim() === "") {
      return;
    }

    // Add user message to the chat
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: userInput, sender: "user" },
    ]);

    try {
      // Send user message to the backend
      const response = await fetch("/api/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userInput }),
      });

      if (!response.ok) {
        throw new Error("Failed to send message to the server");
      }

      // Process the backend response and add it to the chat
      const responseData = await response.json();
      const botResponse = responseData.message;

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: botResponse, sender: "bot" },
      ]);

      // Clear user input
      setUserInput("");
    } catch (error) {
      console.error("Error sending message to the server:", error);
    }
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const closeChat = () => {
    setIsChatOpen(false);
  };

  return (
    <div class="chat">
      {isChatOpen ? (
        <>
          <div className={`chat-container ${isChatOpen ? "open" : "closed"}`}>
            <div className="header">
              {/* <div className="close-icon">X</div> */}
              <img src={x} className="close-icon" onClick={closeChat} />
            </div>

            {/* <div className="messages">
              {messages.map((message, index) => (
                <div key={index} className={`message ${message.sender}`}>
                  {message.text}
                </div>
              ))}
            </div>
            <div className="input-container">
              <input
                className="chatInput"
                type="text"
                value={userInput}
                onChange={handleUserInput}
                placeholder="Type your message..."
              />
              <div class="sending">
                <img src={send} onClick={handleSendMessage} />
              </div>
            </div> */}
          </div>
        </>
      ) : (
        <img src={chat} className="messenger-image" onClick={toggleChat} />
      )}
    </div>
  );
};

export default ChatComponent;
