import React, { useState, useEffect } from "react";
import dtcover from "../images/home/dtc.png";
import about from "../images/about/about.png";
import AboutButton from "../components/AboutButton";
import "../css/about.scss";
import Card2 from "../components/Card2";
import AOS from "aos";
import "aos/dist/aos.css";
const About = () => {
  useEffect(() => {
    setTimeout(() => {
      AOS.refresh();
    }, 1000);
  }, []);
  return (
    <div class="about">
      <div class="section1">
        <img src={dtcover} alt="hero" className="aboutcover-image" />
        <div
          class="section1-in"
          data-aos="zoom-out"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div class="section1-title">About Us</div>
        </div>
      </div>
      <Card2>
        <div class="section2">
          <div class="About-image-container">
            <img
              src={about}
              alt="hero"
              className="about-image"
              data-aos="fade"
              data-aos-duration="2000"
              data-aos-delay="300"
              // data-aos-anchor=".section2"
              // data-aos-anchor-placement="bottom-bottom"
            />
          </div>
          <div
            class="s2content"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <div class="s2title">Why You Should Choose Us?</div>
            <div class="s2para">
              Make your space better suited to its purpose by choosing a good
              interior design. Our assignments are handled by our expertise team
              of architects, residence and corporate interiors, showroom
              interiors, and design engineers. When you choose Splash Interior ,
              the best home interior designers in Chennai, to design your
              interiors, they choose the best of everything for your interior
              decorations on your budget. The efficiently experienced interior
              designers in Splash Interior are totally aware of the potential
              obstacles and the delays possible in the process of working on a
              project. Splash Interior will assist you to reduce and avoid
              unnecessary spending and expensive mistakes at the same time.
            </div>
            <div class="s2buttonsContainer">
              <AboutButton />
              {/* <AboutButton text="Vision" /> */}
            </div>
          </div>
        </div>
      </Card2>
    </div>
  );
};
export default About;
