import React from "react";
import dtcover from "../images/home/dtc.png";
import commercial from "../images/service/commercial.png";
import cid1 from "../images/service/cid1.png";
import cid2 from "../images/service/cid2.png";
import cid3 from "../images/service/cid3.png";
import cid4 from "../images/service/cid4.png";
import cid5 from "../images/service/cid5.png";
import ServicesSlides from "../components/ServicesSlides";
import Card2 from "../components/Card2";
import Servicecomponent from "../components/Servicecomponent";
const CommercialDesign = () => {
  const CidImageData = [
    {
      image: cid1,
    },
    {
      image: cid2,
    },
    {
      image: cid3,
    },
    {
      image: cid4,
    },
    {
      image: cid5,
    },
  ];

  const CidData = {
    coverimage: dtcover,
    subtitle: "Commercial Interior Design",
    image: commercial,
    title: "Best Office Interior Designers in Chennai",
    para1:
      "Splash Interiors has many rich and ample experiences for office interiors. We provide varied designing patterns and concepts for Commercial Interior Design. We design your workspace and create innovation using advanced software and latest trending design ideas. Splash Interiors for a Commercial Interior Design offers,",
    li1: "Beautiful & functional workspace in less time",
    li2: "Ensures the project is moving on time and on budget",
    li3: "Manage all the designing ideas",
    li4: "Plan requirements and schedule parties, contractors appropriately",
    para5:
      "Commercial buildings need different ideas which are ought to be different from homes and residential designs. Our Commercial Interior Design in Chennai will help you to design in such a way that makes an impact in the designer’s job.",
    para6:
      "The commercial interior designer balances the functional in terms of physical space and aesthetic. There is a quote that says location is the key to the successful business. Although with the famous location, business ought to fall down if it has a failing interior design. The successful structure requires more than just a location. We design the commercial building in such a way that it fulfills the business purposes..",
    para7:
      "We, the Commercial interior designers design in such a way that edifies the basic function of a building framework. The commercial designers help businesses make money with their astounding designs which enhances the ambience of the workspace. We make notable changes and utilize the space in every effective way. Our designers meet every infrastructure needs in a unique way. The considerable challenges in the effective use of interior space, when manipulated appropriately can increase the efficacy of the infrastructure.",
    para8:
      "Splash Interiors’s very own designers can help you with all aspects of commercial & residential interior design. From trade to market, reception to board rooms we bring out the right people and carry out the smooth design process. We, the Splash Interiors patrons and look forward to being in, for the increased result in patronage and revenues.",
    downhead: "",
  };

  return (
    <div class="cid">
      <Servicecomponent Data={CidData} ImageData={CidImageData} />
    </div>
  );
};
export default CommercialDesign;
