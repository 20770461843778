import React, { useRef, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import s1 from "../images/home/s1.png";
import s2 from "../images/home/s2.png";
import s3 from "../images/home/s3.png";
import s4 from "../images/home/s4.png";
import s5 from "../images/home/s5.png";
import s6 from "../images/home/s6.png";
import s7 from "../images/home/s7.png";
import s8 from "../images/home/s8.png";
import s9 from "../images/home/s9.png";
import s10 from "../images/home/s10.png";
import s11 from "../images/home/s11.png";
import s12 from "../images/home/s12.png";
import redirect from "../images/home/redirect.svg";
import "../css/ourservices.scss";
import { useHistory } from "react-router-dom";

const OurservicesComponent = () => {
  const history = useHistory();

  const servicesData = [
    {
      image: s1,
      title: "Dressing Table",
      para: "Get better storage & style with intricately designed tables",
      navigationimage: redirect,
    },
    {
      image: s2,
      title: "False Ceiling",
      para: "Add that extra pop to your living room with richly crafted false celling",
      navigationimage: redirect,
    },
    {
      image: s3,
      title: "Modular Design",
      para: "Your Kitchen is where all the magic happens...doesn’t it deserve a modern makeover?",
      navigationimage: redirect,
    },
    {
      image: s4,
      title: "Tv Unit",
      para: "Why keep the TV alone and bare when you can build a great display out of it?",
      navigationimage: redirect,
    },
    {
      image: s5,
      title: "Wall Paintings",
      para: "Let your walls narrate stories with vibrant colors and mesmerizing shapes!",
      navigationimage: redirect,
    },
    {
      image: s6,
      title: "Wardrobe Design",
      para: "Elegant look & maximum storage space in one go!",
      navigationimage: redirect,
    },
    {
      image: s7,
      title: "Interior Work",
      para: "Crafting spaces that inspire.",
      navigationimage: redirect,
    },
    {
      image: s8,
      title: "Grills and Fabrications",
      para: "Transforming metal into functional art.",
      navigationimage: redirect,
    },
    {
      image: s9,
      title: "UPVC Window",
      para: "Efficiency meets elegance in every frame.",
      navigationimage: redirect,
    },
    {
      image: s10,
      title: "Mosquito Net",
      para: "Uninterrupted sleep, naturally guarded",
      navigationimage: redirect,
    },
    {
      image: s11,
      title: "Wooden Paneling",
      para: "Elevating interiors with timeless warmth",
      navigationimage: redirect,
    },
    {
      image: s12,
      title: "Painting (Asian Paints)",
      para: "Coloring your world with Asian Paints excellence",
      navigationimage: redirect,
    },
  ];

  const navigateToservicedetails = (index) => {
    history.push({
      pathname: "/serviceDetails",
      state: {
        index: index,
      },
    });
  };

  return (
    <div className="services-container">
      <div class="services-card-container">
        {servicesData.map((each, index) => (
          <div
            className="services-card"
            data-aos="fade"
            data-aos-duration="600"
            data-aos-delay={index * 400}
          >
            <img src={each.image} alt="app" className="service-image" />
            <div class="cardcontent-container">
              <div class="card-content">
                <div class="services-title">{each.title}</div>
                <div class="services-para">{each.para}</div>
              </div>
              <img
                src={each.navigationimage}
                alt="navigation-image"
                className="navigation-image"
                onClick={() => navigateToservicedetails(index)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default OurservicesComponent;
