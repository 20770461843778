import React, { useEffect } from "react";
import "../css/headdata.scss";
import Card2 from "./Card2";
import AOS from "aos";
import "aos/dist/aos.css";
const HeadData = ({ headData }) => {
  useEffect(() => {
    setTimeout(() => {
      AOS.refresh();
    }, 1000);
  }, []);
  return (
    <Card2>
      <div className="headdata-container">
        {headData.map((each, index) => (
          <div
            class="headdata"
            data-aos="fade-right"
            data-aos-duration="500"
            data-aos-delay={index * 200}
          >
            <div class="Ridtitle">{each.head}</div>
            <div class="ridpara">{each.para}</div>
          </div>
        ))}
      </div>
    </Card2>
  );
};
export default HeadData;
