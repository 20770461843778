import React from "react";
import "../css/readmore.scss";
const Readmorebutton = ({ name, link }) => {
  return (
    <div className="demo-button">
      <a className="demorealButton" href={link}>
        {name}
      </a>
    </div>
  );
};
export default Readmorebutton;
