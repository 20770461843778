// import React from "react";
import React, { useState, useEffect } from "react";

import "../css/home.scss";
import hero1 from "../images/home/hero1.png";
import hero2 from "../images/home/hero2.png";
import hero3 from "../images/home/hero3.png";
import hero4 from "../images/home/hero1-1.png";
import hero5 from "../images/home/hero2-2.png";
import hero6 from "../images/home/hero3-3.png";
import about from "../images/home/about.png";
import hero1a from "../images/home/hero1a.png";
import hero2a from "../images/home/hero2a.png";
import hero3a from "../images/home/hero3a.png";

import Readmorebutton from "../components/Readmore";
import why from "../images/home/why.png";
import Card2 from "../components/Card2";
import OurservicesComponent from "../components/OurservicesComponent";
import InteriorComponent from "../components/InteriorComponent";
import Carousel from "../components/Carousel";
import gal1 from "../images/home/gal1-1.png";
import gal2 from "../images/home/gal2-1.png";
import gal3 from "../images/home/gal3-1.png";
import gal4 from "../images/home/gal4-1.png";
import attractive from "../images/home/attractive.png";
import VirtualDesComponent from "../components/virtualDesComponent";
import TestominialCarousel from "../components/TestominialCarousel";
import Form from "../components/form";
import AOS from "aos";
import "aos/dist/aos.css";
const Home = () => {
  useEffect(() => {
    setTimeout(() => {
      AOS.refresh();
    }, 1000);
  }, []);
  const initialData = [
    {
      coverimage: hero1,
      coverimage1: hero4,
      title: "Home Decoration",
      para: "Improve the aesthetic and ambiance of your home.",
    },
    {
      coverimage: hero2,
      coverimage1: hero5,
      title: "Modular Kitchen",
      para: "Enhance the look of your kitchen to make it spacious, convenient and stylish",
    },
    {
      coverimage: hero3,
      coverimage1: hero6,
      title: "Crafting a lovely home for you ",
      para: "Skilled Interior Designers at Splash Interiors",
    },
  ];

  const extracontent = [
    {
      para: "Splash Interiors plans and exceptionally designs your modular kitchen Chennai and your entire interior design of your home or workplace. We know what goes where. Every element will go in the right place to spruce up the interior design. Our team will always give you something extra and strive to exceed any expectations you have of us. We believe that the right design is possible only through the right mindset. Splash Interiors has worked across every segment of interior designing from traditional to modern designs.",
    },
    {
      para: "Our team of proficient interior decorators in Chennai have acquired a unique skill set to transform your house or corporate space into a piece of art. Splash Interiors is one of the trend-setters in the interior design world. We will definitely bring distinct creativity to the table. We start by understanding your needs and expectations. Only then we will proceed further with the design. This workflow allows us to produce the outcome you love. Our Top interior designers in Chennai think and act differently to provide the best services that conforms to the highest quality standards",
    },
    {
      para: "Splash Interiors has worked on many modular kitchen Chennai, residential, and corporate design projects. The different projects that we take have exposed us to different situations. It has increased our expertise since we tackle new challenges and work our way to success. Satisfying customers in each one of the projects have made us the top home interior designers in Chennai and Modular Kitchen Chennai. We give a certain charm to the atmosphere and bring out appropriate ambiance through our designs. The elements that we incorporate into every design have distinguished us from our competitors.",
    },
    {
      para: "Our execution will flow seamlessly as we plan everything ahead of time and leave some room for improvisions. The primary aim in any project we work on is to enhance both the look and utility of the space. Our decorators and designers work together with a single-minded goal of aligning our creative side with that of the client’s expectations. As we have efficient and Top Interior Designers In Chennai in our team who can work in a variety of situations as said earlier We can design any home, be it a 2 BHK, 3 BHK, or Luxury House, and ensure that we deliver the output within the fixed time and budget.",
    },
    {
      para: "Interior design is an art since it requires out-of-the-box thinking. We persistently figure out everything that can make the space more appealing and also useful to residents. The placement of certain elements will definitely have an impact on the overall design. Therefore, our team of Interior Decorators In Chennai are very skilled in their approach to pick out the exact placements of each design component. Our consistent service has garnered us a loyal customer base and earned valuable trust of our target market. We always work to achieve excellence and stay updated with the current trends prevailing in the market.Splash Interiors has surpassed the expectations of our customers. We believe in adding value to you at every step of the process. Our reliable team of home interior designers In Chennai have made this feat possible through their efforts in transforming the thoughts of the customers into something tangible and a beautiful piece of art.Contact us now for any interior design and decoration needs!",
    },
  ];

  const [chronicData, setChronicData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCarousel, setshowCarousel] = useState(false);

  const [showcontent, setshowcontent] = useState(false);
  const onMouseHandler = () => {
    // if (showCarousel) {
    setshowCarousel((prevState) => {
      return !prevState;
    });
    // }
  };

  const togglebutton = () => {
    // if (showCarousel) {
    setshowcontent((prevState) => {
      return !prevState;
    });
    // }
  };

  useEffect(() => {
    const updateContent = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % initialData.length);
    };

    const intervalId = setInterval(updateContent, 2000); // Change content every 5 seconds

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    // Set the initial data when the component mounts
    setChronicData(initialData[currentIndex]);
  }, [currentIndex]);
  return (
    <div className="home">
      <div class="section1-out">
        <img src={chronicData.coverimage} alt="hero" className="cover-image" />
        <img
          src={chronicData.coverimage1}
          alt="hero"
          className="cover-image1"
        />

        <div class="section1-in">
          <div class="section1-title">{chronicData.title}</div>
          <div class="section1-para">{chronicData.para}</div>
        </div>
      </div>
      <Card2>
        <div class="section2">
          <div class="aboutimgContainer">
            <img
              src={about}
              alt="about"
              className="aboutimg"
              data-aos="fade"
              data-aos-duration="1500"
              data-aos-anchor=".section2"
              data-aos-anchor-placement="top-center"
              data-aos-delay="300"
            />
          </div>
          <div class="section2-right">
            <div
              class="s2-title"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="300"
            >
              About Us
            </div>
            <div
              class="s2-para"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="300"
            >
              A house built of bricks is made home with the aesthetics within
              the house. Your vision of an ideal interior of your home starts
              right from the moment when you enter your house. Splash Interiors,
              the best Interior Designers, have the resources and experience to
              bring your dream to life. We have a professional dedicated team to
              take the responsibility for fulfilling your vision. We make your
              home space more adoring which fills the place with serenity and
              satisfies your senses. We show our love and passion for our work
              through our design outcome. We have drastically reduced the
              waiting and turnaround time as we have a scheduled workflow to
              work and complete the work on time as per the client’s
              requirement. Our team is always ready to produce the masterpiece
              that you have in your mind and we have a diverse client portfolio.
              Our team at Splash Interiors always aims to achieve excellence in
              both the looks of the outcome and the utility of the designs that
              we choose to make.
            </div>
            <div
              class="homeReadbutton"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="300"
            >
              <Readmorebutton name={"Read More"} link={"/AboutUs"} />
            </div>
          </div>
        </div>
      </Card2>
      <div class="section3">
        <div
          class="section3-title"
          data-aos="fade-up"
          data-aos-duration="300"
          data-aos-delay="300"
          data-aos-anchor=".section3"
          data-aos-anchor-placement="top-center"
        >
          Our Services
        </div>
        <OurservicesComponent />
      </div>
      <Card2>
        <div class="section4">
          <div class="s4content-container">
            <div
              class="s4content"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="300"
              data-aos-anchor=".section4"
              data-aos-anchor-placement="top-center"
            >
              <div class="s4title">Why </div>
              <div class="s4title">Interior Splash?</div>
            </div>
            <div class="s4-interior">
              <InteriorComponent />
            </div>
          </div>
          <img
            src={why}
            alt="about"
            className="interiorworkimg"
            data-aos="fade"
            data-aos-duration="300"
            data-aos-delay="300"
          />
        </div>
      </Card2>
      <Card2>
        <div class="section5">
          <div
            class="section5title"
            data-aos="fade-up"
            data-aos-duration="300"
            data-aos-delay="300"
          >
            Our Interior Gallery Product
          </div>
          <div class="section5-container" onMouseEnter={onMouseHandler}>
            <div
              class="section5-item"
              // data-aos="flip-left"
              // data-aos-easing="ease-out-cubic"
              // data-aos-duration="2000"
            >
              <img src={gal1} alt="about" className="galimg" />
            </div>
            <div
              class="section5-item double-column"
              // data-aos="flip-left"
              // data-aos-easing="ease-out-cubic"
              // data-aos-duration="2000"
              // data-aos-delay="500"
            >
              <div class="section5-item">
                <img src={gal2} alt="about" className="galimg" />
              </div>
              <div class="section5-item">
                <img src={gal3} alt="about" className="galimg" />
              </div>
            </div>
            <div
              class="section5-item"
              // data-aos="flip-left"
              // data-aos-easing="ease-out-cubic"
              // data-aos-duration="2000"
              // data-aos-delay="500"
            >
              <img src={gal4} alt="about" className="galimg" />
            </div>
          </div>
          <div
            class="gallery-but"
            data-aos="fade-up"
            data-aos-duration="300"
            data-aos-delay="300"
          >
            <Readmorebutton name={"View more Gallery"} link={"/Gallery"} />
          </div>

          {showCarousel && (
            <div class="s5carousel-container">
              <Carousel />
            </div>
          )}
        </div>
      </Card2>
      <Card2>
        <div class="section6">
          <div class="attractiveimgContainer">
            <img
              src={attractive}
              alt="about"
              className="attractiveimg"
              data-aos="fade"
              data-aos-duration="1500"
              data-aos-delay="300"
              // data-aos-anchor=".section5"
              // data-aos-anchor-placement="top-center"
            />
          </div>
          <div class="s6right">
            <div class="s6-righttopcontent">
              <div
                class="s6toptitle"
                data-aos="fade-up"
                data-aos-duration="300"
                data-aos-delay="300"
              >
                Attractive Interiors Starts From
              </div>
              <div
                class="s6toppara"
                data-aos="fade-up"
                data-aos-duration="300"
                data-aos-delay="300"
                // data-aos-anchor=".section5"
                // data-aos-anchor-placement="top-center"
              >
                2BHK FOR 2.75 LAKH & 3BHK FOR 3.75 LAKH
              </div>
            </div>
            <div
              class="s6-downcontent"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="300"
              // data-aos-anchor=".section5"
              // data-aos-anchor-placement="top-center"
            >
              <div class="s6downpara">
                Modular Kitchen - Wardrobe - Loft - False Ceiling - Pooja Unit-
                Kitchen Chimney - T.V Unit - Crockery Unit
              </div>
              <div class="s6downpara">5 Years Free Replacement Warranty</div>
              <div class="conditions">*Conditions Apply</div>
            </div>
          </div>
        </div>
      </Card2>
      <Card2>
        <div class="section7">
          <div
            class="s7title"
            data-aos="fade-up"
            data-aos-duration="300"
            data-aos-delay="300"
          >
            Stay Safe - Design Virtually
          </div>
          <VirtualDesComponent />
        </div>
      </Card2>
      <Card2>
        <div class="section8">
          <div
            class="section8-container"
            data-aos="flip-left"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <div class="s8title">
              Best Interior Designers- Splash Interiors{" "}
            </div>
            <div class="s8para">
              When was the last time a blank wall looked beautiful? Never! The
              interior design adds elegance and ambiance to the room, be it
              residential or commercial. Splash Interiors can do that for you.
              We have been in this industry for years and have always adapted to
              the dynamic tastes and preferences of our customers. Since we
              equip ourselves with the latest trends, the relevancy and quality
              of our designs are unparalleled. Our team is very proficient in
              translating your ideas into enticing visuals on your walls. The
              uniqueness in every design that we make has given us the
              reputation of being the Best Interior Designers in Chennai.
            </div>
            {showcontent && (
              <div class="s8para-more-container">
                {extracontent.map((each) => (
                  <div class="s8para-more">{each.para}</div>
                ))}
              </div>
            )}

            <div class="s8readbutton" onClick={togglebutton}>
              {showcontent ? (
                <Readmorebutton name={"Read Less"} />
              ) : (
                <Readmorebutton name={"Read More"} />
              )}
            </div>
          </div>
        </div>
      </Card2>
      <div class="section9">
        <div
          class="s9title"
          data-aos="fade-up"
          data-aos-duration="300"
          data-aos-delay="300"
        >
          Testimonials
        </div>
        <TestominialCarousel />
      </div>
      <Form />
    </div>
  );
};
export default Home;
