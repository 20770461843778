import React, { useState, useCallback, useRef } from "react";
import Card2 from "./Card2";
import TextAreaField from "../components/textAreaField";
import smile from "../images/blog/smile.svg";
import "../css/commentbox.scss";
const CommentBox = ({ onCommentPost }) => {
  const [comment, setComment] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleEmojiClick = (emoji) => {
    setComment((prevComment) => prevComment + emoji);
  };

  const handlePostComment = () => {
    // Notify the parent component to add the comment
    onCommentPost(comment);
    // Reset comment input and hide emoji picker
    setComment("");
    setShowEmojiPicker(false);
  };

  //   const alwaysValidated = (value) => {
  //     return true;
  //   };
  //   const noCharValidateCheck = (charValue) => {
  //     return true;
  //   };

  //   const error_message = {
  //     comment: "",
  //   };
  //   const resetRefs = {
  //     comment: useRef(null),
  //   };
  //   const [inputData, setInputData] = useState({
  //     comment: "",
  //   });
  //   const [inputDataValidity, setInputDataValidity] = useState({
  //     fullName: true,
  //     emailId: true,
  //     complaintDoctorName: true,
  //   });
  //   const storeData = useCallback((fieldName, value, validityStatus) => {
  //     setInputData((prevState) => {
  //       // console.log(prevState);
  //       return { ...prevState, [fieldName]: value };
  //     });
  //     setInputDataValidity((prevState) => ({
  //       ...prevState,
  //       [fieldName]: validityStatus,
  //     }));
  //   }, []);
  return (
    <div class="comment-box-container">
      {/* <Card2> */}
      <div className="comment-box">
        <div class="commenttitle">Comment</div>
        <div class="TextAreaField">
          <textarea
            className="commenttextarea"
            placeholder="Add a comment..."
            value={comment}
            onChange={handleCommentChange}
          />
          <img
            src={smile}
            className="smile"
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          />
        </div>

        <div className="emoji-picker">
          {showEmojiPicker && (
            <div>
              {Array.from({ length: 128 }, (_, index) => (
                <button
                  key={index}
                  onClick={() =>
                    handleEmojiClick(String.fromCodePoint(index + 128512))
                  }
                >
                  {String.fromCodePoint(index + 128512)}
                </button>
              ))}
            </div>
          )}
        </div>

        <div class="buttons-container">
          <div className="cancel" onClick={handlePostComment}>
            cancel
          </div>
          <div className="post" onClick={handlePostComment}>
            Post
          </div>
        </div>
      </div>
      {/* </Card2> */}
    </div>
  );
};

export default CommentBox;
