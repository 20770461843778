import React, { useRef, useCallback, useState } from "react";
import InputTextField from "../components/InputTextField";
import "../css/inputTextField.scss";
import "../css/textAreaField.scss";
import TextAreaField from "../components/textAreaField";
import "../css/enquiry.scss";
import x from "../images/home/x.svg";
const Enquiry = () => {
  const [isenquireOpen, setisenquireOpen] = useState(false);

  const error_message = {
    firstName: "",
    phonenumber: "",
    emailId: "",
    query: "",
  };

  const resetRefs = {
    firstName: useRef(null),
    phonenumber: useRef(null),
    emailId: useRef(null),
    query: useRef(null),
  };

  const [inputData, setInputData] = useState({
    firstName: "",
    phonenumber: "",
    emailId: "",
    query: "",
  });

  const [inputDataValidity, setInputDataValidity] = useState({
    fullName: true,
    emailId: true,
    complaintDoctorName: true,
  });
  const alwaysValidated = (value) => {
    return true;
  };
  const noCharValidateCheck = (charValue) => {
    return true;
  };
  const storeData = useCallback((fieldName, value, validityStatus) => {
    setInputData((prevState) => {
      // console.log(prevState);
      return { ...prevState, [fieldName]: value };
    });
    setInputDataValidity((prevState) => ({
      ...prevState,
      [fieldName]: validityStatus,
    }));
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    //    console.log(formdata);
    // if (isVerified != "") {
    // console.log(formData);
    // await fetch(FORMSPARK_ACTION_URL, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    //   body: JSON.stringify({
    //     firstname: inputData.firstName,
    //     lastname: inputData.lastName,
    //     email: inputData.emailId,
    //     message: inputData.query,
    //   }),
    // });
    // alert("Form submitted");
    // console.log("Form submitted successfully!");

    // Object.keys(resetRefs).forEach((value) => {
    //   resetRefs[value].current.reset_data();
    // });
  };

  const toggleenquire = () => {
    setisenquireOpen(!isenquireOpen);
  };

  const closeenquireChat = () => {
    setisenquireOpen(false);
  };

  return (
    <>
      {isenquireOpen ? (
        <div className="Enquiry">
          <div class="Enquiry-container">
            <div class="enqX" onClick={closeenquireChat}>
              <img src={x} alt="x" className="enquiryX" />
            </div>
            <div class="enq-title">Quick Contact</div>
            <div class="enq-para">
              Contact us today, and get reply with in 24 hours!
            </div>

            <form netlify>
              <div class="enqFields">
                <InputTextField
                  ref={resetRefs["firstName"]}
                  text="First Name"
                  containerClassName="colSpan1"
                  validation={alwaysValidated}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["firstName"]}
                  storeData={storeData.bind(null, "firstName")}
                  placeholder="Name*"
                  superText=""
                />
                <InputTextField
                  ref={resetRefs["phonenumber"]}
                  text="phonenumber"
                  containerClassName="colSpan1"
                  validation={alwaysValidated}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["lastName"]}
                  storeData={storeData.bind(null, "lastName")}
                  placeholder="Phone no"
                  superText=""
                />
                <InputTextField
                  ref={resetRefs["emailId"]}
                  text="emailId"
                  containerClassName="colSpan1"
                  validation={alwaysValidated}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["emailId"]}
                  storeData={storeData.bind(null, "emailId")}
                  placeholder="Email*"
                  superText=""
                />
                <TextAreaField
                  ref={resetRefs["query"]}
                  text="query"
                  containerClassName="colSpan1"
                  validation={alwaysValidated}
                  charValidate={noCharValidateCheck}
                  error_message={error_message["query"]}
                  storeData={storeData.bind(null, "query")}
                  placeholder="Type your message here"
                  superText=""
                />
              </div>

              <div class="enqSubmit">
                <button
                  className="enqsubmitButton"
                  onClick={onSubmit}
                  type="submit"
                >
                  Submit now
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="enquireBox" onClick={toggleenquire}>
          Enquiry Now
        </div>
      )}
    </>
  );
};
export default Enquiry;
