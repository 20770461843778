import React from "react";
import logo from "../images/home/splashIcon.svg";
import location from "../images/home/location.svg";
import call from "../images/home/call.svg";
import mail from "../images/home/mail.svg";
import map from "../images/home/map.png";
import instagram from "../images/home/instagram.svg";
import facebook from "../images/home/facebook.svg";
import whatsapp from "../images/home/whatsapp.svg";
import youtube from "../images/home/youtube.svg";
import "../css/footer.scss";
import Card2 from "./Card2";
const Footer = () => {
  const footerdata = [
    {
      icon: location,
      para: "No. 10 & 23, Second floor, Bazaar Main Road,Ram nagar, Madipakkam,Chennai, Tamil Nadu 600091",
    },
    {
      icon: call,
      para: "+91 44 4785 0035",
    },
    {
      icon: mail,
      para: "mailus@foxibe.com",
    },
  ];
  const socialdata = [
    {
      media: instagram,
    },
    {
      media: facebook,
    },
    {
      media: youtube,
    },
    {
      media: whatsapp,
    },
  ];
  return (
    <div class="footer">
      <Card2>
        <div className="Footercontainer">
          <div class="foot1" data-aos="fade-up" data-aos-duration="500">
            <img src={logo} alt="" className="footLogo" />
            <div class="f1Para">
              Chennai from Home and perfect for an unmatched target setting
              process, So we thought the interior design of the controversial
              legacy has given new dimensions to introduce ourselves as one of
              the leading interior design company.
            </div>
          </div>

          <div
            class="foot2"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="300"
          >
            <div class="f2Title">Contact Details</div>
            {footerdata.map((each, index) => (
              <div class="f2content">
                <div class="f2content1">
                  <img src={each.icon} alt="" className="footicons" />
                  <div class="f2para">{each.para}</div>
                </div>
              </div>
            ))}
            <div class="f2content2">
              <div class="follow">Follow us</div>
              {socialdata.map((each, index) => (
                <img src={each.media} alt="" className="sociallinks" />
              ))}
            </div>
          </div>

          <div
            class="foot3"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="500"
          >
            <div class="f3title">Quick Links</div>
            <div class="socialmedialinks">
              <a href="/home" className="links">
                Home
              </a>
              <a href="/AboutUs" className="links">
                About
              </a>
              <a href="/Services" className="links">
                Services
              </a>
              <a href="/Gallery" className="links">
                Gallery
              </a>
              <a href="/Contact" className="links">
                Contact Us
              </a>
            </div>
          </div>

          <div
            class="foot4"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="700"
          >
            <div class="f4title">Location</div>
            <img src={map} alt="" className="map" />
          </div>
        </div>
      </Card2>
    </div>
  );
};
export default Footer;
