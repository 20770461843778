import React from "react";
import calendar from "../images/home/calender.svg";
import bulb from "../images/home/bulb.svg";
import home from "../images/home/home.svg";
import tick from "../images/home/tick.svg";
import "../css/interiorcomp.scss";
const InteriorComponent = () => {
  const interiorData = [
    {
      icon: calendar,
      title: "Installation Days",
    },
    {
      icon: home,
      title: "1500+ Happy Customers",
    },
    {
      icon: bulb,
      title: "100+ Design Experts",
    },
    {
      icon: tick,
      title: "5+ Years Replacement Warranty",
    },
  ];
  return (
    <div className="InteriorComponent">
      {interiorData.map((each, index) => (
        <div
          class="interiorCard"
          data-aos="fade"
          data-aos-duration="600"
          data-aos-delay={index * 200}
        >
          <img src={each.icon} alt="hero" className="interior-image" />
          <div class="interiorPara">{each.title}</div>
        </div>
      ))}
    </div>
  );
};
export default InteriorComponent;
