import React, { useState, useEffect } from "react";
import "../css/blogcomponent.scss";
import Card2 from "./Card2";
import CommentBox from "../components/Commentbox";
import classNames from "classnames";
// import chev
// import AOS from "aos";
// import "aos/dist/aos.css";
const BlogComponent = ({ blogs, blogdetails, key, onClick, index }) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     AOS.refresh();
  //   }, 1000);
  // }, []);

  const [viewblog, setviewblog] = useState(false);
  const viewToggle = () => {
    setviewblog((prev) => !prev);
  };

  const handleindex = (index) => {
    onClick(index);
  };

  return (
    <Card2>
      <div className="blog-container">
        <div
          class="blogContent-container"
          key={key}
          onClick={() => {
            viewToggle();
            handleindex(index);
          }}
          data-aos="flip-down"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div class="blog-image-container">
            <img src={blogs.image} className="blogImage" />
          </div>
          <div class="blogContent">
            <div class="blogTitle">{blogs.title}</div>

            {viewblog ? (
              <div class="blogpara">{blogs.fullpara}</div>
            ) : (
              <div class="blogpara">{blogs.para}</div>
            )}
          </div>
        </div>

        {viewblog && (
          <div className="blogdetails">
            {blogdetails?.desc?.map((each, index) => (
              <div class="blogDetails-container">
                {each.title && <div class="bdtitle">{each.title}</div>}
                {each.para && <div class="bdpara">{each.para}</div>}
              </div>
            ))}
            <div class="bdpara1">{blogdetails?.para8}</div>
            <div class="bdpara1">{blogdetails?.para9}</div>
            <CommentBox />
          </div>
        )}
      </div>
    </Card2>
  );
};
export default BlogComponent;
