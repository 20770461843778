import React, { useState, useEffect } from "react";
import dtable from "../images/home/dtable.png";
import falseceiling from "../images/home/falseceiling.png";
import modularkit from "../images/home/modularkit.png";
import tvunit from "../images/home/tvunit.png";
import wall from "../images/home/wall.png";
import wardrobe from "../images/home/wardrobe.png";
import interiorworks from "../images/home/interiorworks.png";
import grills from "../images/home/grills.png";
import upvc from "../images/home/upvc.png";
import wooden from "../images/home/wooden.png";
import mosquito from "../images/home/mosquito.png";
import painting from "../images/home/painting.png";
import dtcover from "../images/home/dtc.png";
import fccover from "../images/home/fccover.png";
import mkc from "../images/home/mkc.png";
import mk1 from "../images/home/mk1.png";
import mk2 from "../images/home/mk2.png";
import mk3 from "../images/home/mk3.png";
import mk4 from "../images/home/mk4.png";
import mk5 from "../images/home/mk5.png";
import mk6 from "../images/home/mk6.png";
import tv1 from "../images/home/tv1.png";
import tv2 from "../images/home/tv2.png";
import tv3 from "../images/home/tv3.png";
import tv4 from "../images/home/tv4.png";
import tv5 from "../images/home/tv5.png";
import wall1 from "../images/home/wall1.png";
import wall2 from "../images/home/wall2.png";
import wall3 from "../images/home/wall3.png";
import wall4 from "../images/home/wall4.png";
import wall5 from "../images/home/wall5.png";
import ward1 from "../images/home/ward1.png";
import ward2 from "../images/home/ward2.png";
import ward3 from "../images/home/ward3.png";
import ward4 from "../images/home/ward4.png";
import ward5 from "../images/home/ward5.png";
import int1 from "../images/home/int1.png";
import int2 from "../images/home/int2.png";
import int3 from "../images/home/int3.png";
import int4 from "../images/home/int4.png";
import int5 from "../images/home/int5.png";
import gri1 from "../images/home/gri1.png";
import gri2 from "../images/home/gri2.png";
import gri3 from "../images/home/gri3.png";
import gri4 from "../images/home/gri4.png";
import gri5 from "../images/home/gri5.png";
import upvc1 from "../images/home/upvc1.png";
import upvc2 from "../images/home/upvc2.png";
import upvc3 from "../images/home/upvc3.png";
import upvc4 from "../images/home/upvc4.png";
import upvc5 from "../images/home/upvc5.png";
import mos1 from "../images/home/mos1.png";
import mos2 from "../images/home/mos2.png";
import mos3 from "../images/home/mos3.png";
import mos4 from "../images/home/mos4.png";
import mos5 from "../images/home/mos5.png";
import wood1 from "../images/home/wood1.png";
import wood2 from "../images/home/wood2.png";
import wood3 from "../images/home/wood3.png";
import wood4 from "../images/home/wood4.png";
import wood5 from "../images/home/wood5.png";
import paint1 from "../images/home/paint1.png";
import paint2 from "../images/home/paint2.png";
import paint3 from "../images/home/paint3.png";
import paint4 from "../images/home/paint4.png";
import paint5 from "../images/home/paint5.png";
import "../css/ourservicedetails.scss";
import { useLocation } from "react-router-dom";

import Card2 from "./Card2";
const OurserviceDetails = () => {
  const location = useLocation();
  const { index } = location.state;
  const servicesData = [
    {
      coverimage: dtcover,
      subtitle: "Dressing Table",
      subpara: "Home / Dressing Table",
      image: dtable,
      title: "Dressing Table",
      para1:
        "Our dressing Table has a cord of balance between Practicality and Elegancy. Our only motto is to match the taste of your convenience and create a personal cozy touch. Our designs are updated to the trends and matched to their individual style. Our dressing tables are not just furniture that is present in your house, but a piece of antique that transforms the entire ambience.",
      para2:
        "It will definitely be an eye catcher in your entire house and effortlessly grabs your entire guest’s attention. Your bedroom gets an update with this beauty.",
      para3:
        "Storing and arranging your accessories in a more linear and ordered manner is an add-on to it. You no longer have to panic during the late hours for a meeting. You will just find what you need in the drawers even at the last minute effortlessly.",
      para4:
        "It can also be an easy substitute for storing all your toiletries and makeup items. It can be your favorite jewelry compartmentalizer and a life saver when you try to find the right piece match your wardrobe.",
      slide1: "",
      slide2: "",
      slide3: "",
      slide4: "",
      slide5: "",
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: fccover,
      subtitle: "False Ceiling",
      subpara: "Home / False Ceiling",
      image: falseceiling,
      title: "False Ceiling",
      para1:
        "The most important element for any interior is the Ceiling. They provide the longest and largest view for any space. So, it is noticeable as soon as a person enters the room. The aesthetic is defined with the help of a ceiling. Due to its huge surface area, it plays a major role in the thermal and acoustic comfort for the space.",
      para2:
        "Our False Ceilings provides an elegant touch to the whole atmosphere. As we know ceiling are the most sensitive part in your space. It makes the whole house beautiful if we design in a perfect way. But if we even miss a slightest detail it may make it dreadful. This is indeed a competitive world.",
      para3:
        "Hence people tend to prove themselves and compete with others through their immersion of innovative ceilings. Plain and wooden concrete are a thing of stone ages. Ceilings have gone through a lot of changes or as we commonly say ‘updates’. People do not think it as a place to live and dwell. They consider it an art to decorate their house and some see their home as their reflection of inner self.",
      para4:
        "At splash Interiors we provide the best false ceilings that could fulfill all your dreams and transform your home into paradise.",
      slide1: "",
      slide2: "",
      slide3: "",
      slide4: "",
      slide5: "",
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: mkc,
      subtitle: "Best Modular Kitchen",
      subpara: "Home / Best Modular Kitchen Chennai",
      image: modularkit,
      title: "Best Modular Kitchen Chennai",
      para1:
        "If this is your first time getting into the world of modular kitchens, you might be confused with all the technical stuff. What goes where? Which design element would suit your kitchen? How would the overall ambiance be once you get the design right? These are the questions you need answers to and Splash Interiors has got that for you! Our team can design the best Modular Kitchen Chennai that looks good and also give you enhanced utility. We focus on improving the design of your kitchen from every aspect. Every project that we worked on helped us accumulate a plethora of skills and creativity needed in this field. We firmly believe that to produce the desired result, both technical and creative fluency is required. Here is what we can do for you:",
      para2: "",
      para3: "",
      para4: "",
      slide1: mk1,
      slide2: mk2,
      slide3: mk3,
      slide4: mk4,
      slide5: mk5,
      li1: "Understand your requirements and plan accordingly",
      li2: "Use the best of our talents to transform our ideas into tangible results",
      li3: "Ensure quality throughout the process",
      li4: "Effectively use design elements to bring out the best look and utility to your kitchen",
      para5:
        "The trend of setting up a stylish kitchen is high in urban areas because of the limited space offered by houses or apartments. Thus, modular kitchen Chennai can be your saviour in building a kitchen that meets all your purposes while improving your functionality. For example, if you are uncomfortable with the lower cabinets as you cannot bend down because of knee pain or have older parents at home, then you can customize your kitchen by having an upper cabinet.",
      para6:
        "Through years of industry experience, Splash Interiors assists in planning and developing the kitchen as per client customization. Therefore, we offer our clients complete guidance and come up with exciting and unique kitchen layout ideas so that you can pick the one which works for you! We also provide a modular kitchen Chennai with extra functionalities like a wall-mounted breakfast counter so you can have more than a kitchen space.",
      para7:
        "Our team knows the perfect placement for all the kitchen cabinets, shelves, countertops, and other modular kitchen elements to enhance the look of your kitchen. We plan and develop an appropriate design that will match the feel that you want to bring to the place. In recent times, the trend of modular kitchen has really picked up among people as they have discovered the endless benefits that they can get out of it. We have everything lined up perfectly for our customers. Our team strives to achieve accuracy in every Modular Kitchen Chennai project, and we are proud to have met the expectations of the customers in all of them.",
      para8:
        "Our team of talented interior designers will work on getting the color palette and the overall design right. A kitchen is always a special place in a home and it needs to offer ultimate functionality. We know where all the cabinets must go and the different dimensions of the shelves. Splash Interiors optimizes your kitchen space to make it spacious and look great visually. We give our best output no matter the nature of the project. Our team guarantees a neat-looking kitchen for you. Here are some of the layouts available in a modular kitchen design.",
    },
    {
      coverimage: dtcover,
      subtitle: "Tv Unit",
      subpara: "Home / Tv Unit",
      image: tvunit,
      title: "Tv Unit",
      para1:
        "Our dressing Table has a cord of balance between Practicality and Elegancy. Our only motto is to match the taste of your convenience and create a personal cozy touch. Our designs are updated to the trends and matched to their individual style. Our dressing tables are not just furniture that is present in your house, but a piece of antique that transforms the entire ambience.",
      para2:
        "It will definitely be an eye catcher in your entire house and effortlessly grabs your entire guest’s attention. Your bedroom gets an update with this beauty.",
      para3:
        "Storing and arranging your accessories in a more linear and ordered manner is an add-on to it. You no longer have to panic during the late hours for a meeting. You will just find what you need in the drawers even at the last minute effortlessly.",
      para4:
        "It can also be an easy substitute for storing all your toiletries and makeup items. It can be your favorite jewelry compartmentalizer and a life saver when you try to find the right piece match your wardrobe.",
      slide1: tv1,
      slide2: tv2,
      slide3: tv3,
      slide4: tv4,
      slide5: tv5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: dtcover,
      subtitle: "Wall Painting",
      subpara: "Home / Wall Painting",
      image: wall,
      title: "Wall Painting",
      para1:
        "A plain wall is a piece of canvas; a palette of colors and little creative imagination can do magic on your walls. Splash Interiors is here to do the magic for you with our professional and creative designers. We at Splash Interiors, make sure to add style to your wall with various wall painting techniques to make the most out of it.",
      para2:
        "Most of us keep fussing over the furniture, accessories, and forget that walls can have more impact in the interiors. So, we at Splash Interiors blend imagination and techniques to make your house visually more attractive and interestings.",
      para3:
        "The best thing about the team of designers is they make sure the painted style blends sensibly with your interiors. Apart from being aesthetic, we imply easy techniques and also pocket-friendly to our customers.",
      para4:
        "Thus, wall painting adds an extra texture and dimension to plain-boring walls that can turn ordinary single color to a beautiful story. If you are confused about choosing the appropriate color or technique we provide you technical advice to give a makeover to your room.",
      slide1: wall1,
      slide2: wall2,
      slide3: wall3,
      slide4: wall4,
      slide5: wall5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: dtcover,
      subtitle: "Wardrobe Design",
      subpara: "Home / Wardrobe Design",
      image: wardrobe,
      title: "Wardrobe Design",
      para1:
        "Are you a person who loves to keep things organised? Then, Splash Interiors is one of the best wardrobe designers and makers to have your things the way you want. We offer your sturdy and stylish wardrobes for your clothes to stay in place.",
      para2:
        "We offer you a wide range of wardrobe styles with premium materials like wood and granite to meet our customer needs. We incorporate our creativity and imagination concealed in our masterpiece to have the best sleek look.",
      para3:
        "We make sure that each rail, shelves, drawer, and cupboard serve their purpose and truly have space for everything. We believe that building a wardrobe is equally important to constructing a home. Because it is something that comes along with your lives and should accommodate all your requirements.",
      para4:
        "A wardrobe reflects an individual's style and hence we try our best to bring out your personality. Thus, we design your wardrobes that are functional and stylish at the same time. Hence, approach Splash Interiors for a hand-picked and appealing wardrobe that satisfies both your needs and style.",
      slide1: ward1,
      slide2: ward2,
      slide3: ward3,
      slide4: ward4,
      slide5: ward5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: dtcover,
      subtitle: "Interior Works",
      subpara: "Home / Interior Works",
      image: interiorworks,
      title: "Interior Works",
      para1:
        "Feel the warmth and freshness of the wood! Splash Interiors is one of the best interior designers in Chennai to give a new dimension to your existing room. We specialize in blending comfort and style for every project we undertake. We strive hard to make your vision turn into reality. So, approach splash Interiors for more attractive interior works that reflect your personal style.",
      para2: "",
      para3: "",
      para4: "",
      slide1: int1,
      slide2: int2,
      slide3: int3,
      slide4: int4,
      slide5: int5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: dtcover,
      subtitle: "Grills and Fabrications",
      subpara: "Home / Grills and Fabrications",
      image: grills,
      title: "Grills and Fabrications",
      para1:
        "When your balcony doesn’t have space for you and your flower pots together then we need to find a way. We at splash Interiors offer you grill and fabrication works for your house that works well for you and your garden. A fully grilled balcony will enhance your balcony and allow you to have space for the flower pots.You can even customize the grill designs at Splash Interiors to match your living space. Partner with our home interior designers in Chennai to uplift your home design.",
      para2: "",
      para3: "",
      para4: "",
      slide1: gri1,
      slide2: gri2,
      slide3: gri3,
      slide4: gri4,
      slide5: gri5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: dtcover,
      subtitle: "UPVC work",
      subpara: "Home / UPVC work",
      image: upvc,
      title: "UPVC work",
      para1:
        "Adorn your windows with more functional UPVC windows! Splash Interiors offers end-to-end customized solutions for your windows. We offer UPVC frames in different colours and sizes to enhance the outlook. Our interior decorators in Chennai bring you an extensive range of windows and panels that complements your interior look. Contact Splash Interiors for unique designs that give a new dimension to your home.",
      para2: "",
      para3: "",
      para4: "",
      slide1: upvc1,
      slide2: upvc2,
      slide3: upvc3,
      slide4: upvc4,
      slide5: upvc5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: dtcover,
      subtitle: "Mosquito Nets",
      subpara: "Home / Mosquito Nets",
      image: mosquito,
      title: "Mosquito Nets",
      para1:
        "There are moments when you want to enjoy the sleep but mosquitos won’t allow you. Our home interior designers in Chennai have brought you mosquito screens for your windows with high-quality loop tape. Our mosquito screens are best in class which are durable and have good toughness. Our product is light in weight and suits any kind of window frame. So approach Splash Interiors to protect your family!",
      para2: "",
      para3: "",
      para4: "",
      slide1: mos1,
      slide2: mos2,
      slide3: mos3,
      slide4: mos4,
      slide5: mos5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: dtcover,
      subtitle: "Wooden Paneling",
      subpara: "Home / Wooden Paneling",
      image: wooden,
      title: "Wooden Paneling",
      para1:
        "Wooden paneling is a trend and we are definitely on board for 2022. We design elegant wooden works for modular kitchen Chennai and all your rooms. You can accentuate your walls not only with paints and wallpaper. The other way to make them stand out is by wooden paneling which adds warmth and style to your living space! Choose splash interiors to add some texture and fun elements!",
      para2: "",
      para3: "",
      para4: "",
      slide1: wood1,
      slide2: wood2,
      slide3: wood3,
      slide4: wood4,
      slide5: wood5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
    {
      coverimage: dtcover,
      subtitle: "Painting ",
      subpara: "Home / Painting ",
      image: painting,
      title: "Painting ",
      para1:
        "Home is the place that you never want to leave! Choose a wide range of colours that will suit your home or commercial space for that perfect look. We are one of the best interior designers in Chennai who adorn your space with personalized interior design to transform any wall with amazing textures. Get the right assistance for all your painting needs from Splash Interiors! Lit up your space with our professionals!",
      para2: "",
      para3: "",
      para4: "",
      slide1: paint1,
      slide2: paint2,
      slide3: paint3,
      slide4: paint4,
      slide5: paint5,
      li1: "",
      li2: "",
      li3: "",
      li4: "",
      para5: "",
      para6: "",
      para7: "",
      para8: "",
    },
  ];
  const [servicedetailsData, setservicesData] = useState([]);
  // const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    // Set the initial data when the component mounts
    setservicesData(servicesData[index]);
  }, [index]);
  return (
    <div className="serviceDetails">
      <div class="servicedetails-1">
        <img
          src={servicedetailsData.coverimage}
          alt="hero"
          className="servicecover-image"
        />
        <div
          class="servicedetails1-in"
          // data-aos="zoom-out"
          // data-aos-duration="1000"
          // data-aos-delay="300"
        >
          <div class="servicedetails1-title">{servicedetailsData.subtitle}</div>
          <div class="servicedetails1-para">{servicedetailsData.subpara}</div>
        </div>
      </div>
      <Card2>
        <div class="servicedetails-2">
          <img
            src={servicedetailsData.image}
            alt="about"
            className="dtableimg"
            // data-aos="fade"
            // data-aos-duration="2000"
            // data-aos-delay="300"
          />
          <div
            class="servicedetails2-content"
            // data-aos="fade-right"
            // data-aos-duration="1000"
            // data-aos-delay="300"
          >
            <div class="sd2title">{servicedetailsData.title}</div>
            <div class="sd2para">{servicedetailsData.para1}</div>
            {servicedetailsData.para2 && (
              <div class="sd2para">{servicedetailsData.para2}</div>
            )}
            {servicedetailsData.para3 && (
              <div class="sd2para">{servicedetailsData.para3}</div>
            )}
            {servicedetailsData.para4 && (
              <div class="sd2para">{servicedetailsData.para4}</div>
            )}
            <ul>
              {servicedetailsData.li1 && (
                <li className="servicelist">{servicedetailsData.li1}</li>
              )}
              {servicedetailsData.li2 && (
                <li className="servicelist">{servicedetailsData.li2}</li>
              )}
              {servicedetailsData.li3 && (
                <li className="servicelist">{servicedetailsData.li3}</li>
              )}
              {servicedetailsData.li4 && (
                <li className="servicelist">{servicedetailsData.li4}</li>
              )}
            </ul>
          </div>
        </div>
      </Card2>
      <Card2>
        <div class="servicedetails-carousel">
          <div class="servicedetails-card-container">
            <img
              src={servicedetailsData.slide1}
              className="servicedetails-image"
            />
            <img
              src={servicedetailsData.slide2}
              className="servicedetails-image"
            />
            <img
              src={servicedetailsData.slide3}
              className="servicedetails-image"
            />
            <img
              src={servicedetailsData.slide4}
              className="servicedetails-image"
            />
            <img
              src={servicedetailsData.slide5}
              className="servicedetails-image"
            />
          </div>
        </div>
      </Card2>

      <Card2>
        <div
          class="service-details-4"
          // data-aos="fade-right"
          // data-aos-duration="1000"
          // data-aos-delay="300"
        >
          <div class="service-details-para">{servicedetailsData.para5}</div>
          <div class="service-details-para">{servicedetailsData.para6}</div>
          <div class="service-details-para">{servicedetailsData.para7}</div>
          <div class="service-details-para">{servicedetailsData.para8}</div>
        </div>
      </Card2>
    </div>
  );
};
export default OurserviceDetails;
