import React, { useState } from "react";
import "../css/carousel.scss";
import gal1a from "../images/home/gal1a.png";
import gal2a from "../images/home/gal2a.png";
import gal3a from "../images/home/gal3a.png";
import gal4a from "../images/home/gal4a.png";
import left from "../images/home/left.svg";
import right from "../images/home/right.svg";
import Card2 from "../components/Card2";
const Carousel = () => {
  const carouselData = [
    {
      image: gal1a,
    },
    {
      image: gal2a,
    },
    {
      image: gal3a,
    },
    {
      image: gal4a,
    },
  ];

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const previousSlide = () => {
    const newIndex =
      (currentSlideIndex - 1 + carouselData.length) % carouselData.length;
    setCurrentSlideIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentSlideIndex + 1) % carouselData.length;
    setCurrentSlideIndex(newIndex);
  };

  return (
    <div className="carousel">
      <button onClick={previousSlide} className="Carouselleftarrow">
        <img src={left} alt="left" className="leftRightbuttons" />
      </button>
      {/* {services_data[currentSlideIndex].map(each => ( */}

      <Card2>
        <div className="carouselslide-container">
          <div className="carouselslide-image">
            <img
              src={carouselData[currentSlideIndex].image}
              alt="blue"
              className="cImage"
            />
          </div>
        </div>
      </Card2>
      <button onClick={nextSlide} className="Carouselrightarrow">
        <img src={right} alt="right" className="leftRightbuttons" />
      </button>
    </div>
  );
};

export default Carousel;
