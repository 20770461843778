import React, { useState, useEffect } from "react";
import backtotop from "../images/home/backtoTop.svg";
import "../css/scrolltotop.scss";
const ScrolltoTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Function to show or hide the button based on scroll position
    const handleScroll = () => {
      if (window.pageYOffset > 20) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="scrolltoTop">
      {showButton && (
        <img
          src={backtotop}
          className="back-to-top-btn"
          onClick={scrollToTop}
        />
      )}
    </div>
  );
};
export default ScrolltoTop;
