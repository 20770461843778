import React, { useState } from "react";
import all1 from "../images/Gallery/all1.png";
import all2 from "../images/Gallery/all2.png";
import all3 from "../images/Gallery/all3.png";
import all4 from "../images/Gallery/all4.png";
import all5 from "../images/Gallery/all5.png";
import all6 from "../images/Gallery/all6.png";
import comm1 from "../images/Gallery/comm1.png";
import comm2 from "../images/Gallery/comm2.png";
import comm3 from "../images/Gallery/comm3.png";
import comm4 from "../images/Gallery/comm4.png";
import comm5 from "../images/Gallery/comm5.png";
import comm6 from "../images/Gallery/comm6.png";
import res1 from "../images/Gallery/res1.png";
import res2 from "../images/Gallery/res2.png";
import res3 from "../images/Gallery/res3.png";
import res4 from "../images/Gallery/res4.png";
import res5 from "../images/Gallery/res5.png";
import res6 from "../images/Gallery/res6.png";
import modd1 from "../images/Gallery/modd1.png";
import modd2 from "../images/Gallery/modd2.png";
import modd3 from "../images/Gallery/modd3.png";
import modd4 from "../images/Gallery/modd4.png";
import modd5 from "../images/Gallery/modd5.png";
import modd6 from "../images/Gallery/modd6.png";
import ind1 from "../images/Gallery/ind1.png";
import ind2 from "../images/Gallery/ind2.png";
import ind3 from "../images/Gallery/ind3.png";
import ind4 from "../images/Gallery/ind4.png";
import ind5 from "../images/Gallery/ind5.png";
import ind6 from "../images/Gallery/ind6.png";
import war1 from "../images/Gallery/war1.png";
import war2 from "../images/Gallery/war2.png";
import war3 from "../images/Gallery/war3.png";
import war4 from "../images/Gallery/war4.png";
import false1 from "../images/Gallery/false1.png";
import false2 from "../images/Gallery/false2.png";
import false3 from "../images/Gallery/false3.png";
import false4 from "../images/Gallery/false4.png";
import false5 from "../images/Gallery/false5.png";
import false6 from "../images/Gallery/false6.png";
import "../css/options.scss";
import Card2 from "./Card2";
function Option({ option, isActive, onClick }) {
  return (
    <div
      className={`option ${isActive ? "active" : ""}`}
      onClick={() => onClick(option)}
    >
      {option}
    </div>
  );
}

// Component for displaying photos
function Photos({ photos }) {
  return (
    <div className="photos-container">
      {photos.map((photo, index) => (
        <img
          key={index}
          src={photo}
          alt={`Photo ${index + 1}`}
          className="galleryimg"
          data-aos="flip-left"
          data-aos-duration="1300"
          data-aos-delay={index * 400}
          // data-aos-easing="ease-out-cubic"
        />
      ))}
    </div>
  );
}
const Options = () => {
  const [selectedOption, setSelectedOption] = useState("All");

  // Define photos for each option (you can replace this with your data source)
  const photosByOption = {
    All: [all1, all2, all3, all4, all5, all6],
    Commercial: [comm1, comm2, comm3, comm4, comm5, comm6],
    Residential: [res1, res2, res3, res4, res5, res6],
    Modular: [modd1, modd2, modd3, modd4, modd5, modd6],
    Industrial: [ind1, ind2, ind3, ind4, ind5, ind6],
    Wardrobe: [war1, war2, war3, war4],
    FalseCelling: [false1, false2, false3, false4, false5, false6],
    // Define photos for other options similarly
  };

  // Handle option click
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  return (
    <div>
      <div>
        <div className="options-container">
          {Object.keys(photosByOption).map((option) => (
            <Option
              key={option}
              option={option}
              isActive={selectedOption === option}
              onClick={handleOptionClick}
            />
          ))}
        </div>
        {selectedOption && <Photos photos={photosByOption[selectedOption]} />}
      </div>
    </div>
  );
};
export default Options;
