import logo from "./logo.svg";
import React, { useEffect } from "react";
import "./App.css";
import NavigationBar from "./components/navigationBar";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import OurserviceDetails from "./components/OurserviceDetails";
import Footer from "./components/Footer";
import About from "./pages/About";
import CommercialDesign from "./pages/CommercialDesign";
import Rid from "./pages/Rid";
import Modularkitchen from "./pages/Modularkitchen";
import IndustrialDesign from "./pages/IndustrialDesign";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog";
import Chatcomponent from "./components/Chatcomponent";
import Enquiry from "./components/Enquiry";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrolltoTop from "./components/ScrolltoTop";
function App() {
  useEffect(() => {
    setTimeout(() => {
      AOS.init();
      AOS.refresh();
    }, 1000);
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <NavigationBar />

        <Switch>
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>
          <Route path="/home" exact>
            <Home />
          </Route>
          <Route path="/serviceDetails">
            <OurserviceDetails />
          </Route>
          <Route path="/AboutUs">
            <About />
          </Route>
          <Route path="/CommercialInteriorDesign">
            <CommercialDesign />
          </Route>
          <Route path="/ResidentialInteriorDesign">
            <Rid />
          </Route>
          <Route path="/ModularKitchenChennai">
            <Modularkitchen />
          </Route>
          <Route path="/Industrialdesign">
            <IndustrialDesign />
          </Route>
          <Route path="/Gallery">
            <Gallery />
          </Route>
          <Route path="/Contact">
            <Contact />
          </Route>
          <Route path="/Blog">
            <Blog />
          </Route>
        </Switch>
        <Footer />
        <Enquiry />
        <ScrolltoTop />
        {/* <Chatcomponent /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
