import React, { useEffect } from "react";
import "../css/servicesslide.scss";
import AOS from "aos";
import "aos/dist/aos.css";
const ServicesSlides = ({ Data }) => {
  useEffect(() => {
    setTimeout(() => {
      AOS.refresh();
    }, 1000);
  }, []);
  return (
    <div className="ServicesSlides-container">
      {Data.map((each, index) => (
        <div
          class="ServicesSlides-card"
          key={index}
          data-aos="fade"
          data-aos-duration="600"
          data-aos-delay={index * 400}
        >
          <img src={each.image} alt="cid" className="servicepageImage" />
        </div>
      ))}
    </div>
  );
};
export default ServicesSlides;
