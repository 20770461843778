import React, { useState } from "react";
import classNames from "classnames";
import "../css/aboutbutton.scss";
const AboutButton = () => {
  const [active, setActive] = useState("mission");

  const handleClick = (activity) => {
    setActive(activity);
  };
  return (
    <div className="aboutbutton">
      <div class="togglebutton-container">
        <div
          className={classNames(
            // "btncomp",
            active === "mission" ? "active" : "visioncomp"
          )}
          onClick={() => handleClick("mission")}
        >
          Mission
        </div>
        <div
          className={classNames(
            // "visioncomp",
            active === "vision" ? "active" : "visioncomp"
          )}
          onClick={() => handleClick("vision")}
        >
          Vision
        </div>
      </div>

      {active === "mission" ? (
        <div className="togglepara">
          Every client we work with should have a trend setting design and
          thereby achieving breathtaking concepts that benchmark a very high
          standard and quality of lifestyle for our clients.
        </div>
      ) : (
        <div className="togglepara">
          To consistently deliver world class and eco-friendly finishings with
          our unique interior design concepts, to deliver in time the
          deliverables accountable to the client.
        </div>
      )}
    </div>
  );
};
export default AboutButton;
