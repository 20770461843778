import React from "react";
import dtcover from "../images/home/dtc.png";
import rid1 from "../images/service/rid1.png";
import rid2 from "../images/service/rid2.png";
import rid3 from "../images/service/rid3.png";
import rid4 from "../images/service/rid4.png";
import rid5 from "../images/service/rid5.png";
import Servicecomponent from "../components/Servicecomponent";
import rid from "../images/service/rid.png";
import HeadData from "../components/Headdata";
const Rid = () => {
  const RidImageData = [
    {
      image: rid1,
    },
    {
      image: rid2,
    },
    {
      image: rid3,
    },
    {
      image: rid4,
    },
    {
      image: rid5,
    },
  ];
  const RidData = {
    coverimage: dtcover,
    subtitle: "Residential interior design",
    image: rid,
    title: "Best Interior Designers In Chennai",
    para1:
      "Splash Interiors has many rich and ample experiences for office interiors. We provide varied designing patterns and concepts for Commercial Interior Design. We design your workspace and create innovation using advanced software and latest trending design ideas. Splash Interiors for a Commercial Interior Design offers,",
    para2:
      "Our vision is that a home's interior should reflect its inhabitants, where they can find solace, inspiration, and comfort. Our design philosophy revolves around blending functionality with aesthetics, ensuring that every corner of your home looks stunning and serves a purpose that enriches your daily life. We believe in,",
    li1: "Exuding a serene and harmonious atmosphere",
    li2: "Going above and beyond aesthetics for enhanced comfort.",
    li3: "Bringing genuine equilibrium to the room.",
    li4: "Optimizing the living space with a focus on ergometry, which considers the human body and its movements.",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    downhead: "",
  };

  const headingsData = [
    {
      head: "Unleash Your Creativity",
      para: "When you choose Splash Interiors, you can access an unparalleled creative experience. Our interior decorators in Chennai are experts at translating your ideas into tangible and awe-inspiring designs. We work closely with our clients, understanding their lifestyles, aspirations, and preferences to create personalized spaces you'll fall in love with whenever you step inside. From luxurious living rooms that exude elegance to cozy bedrooms that surround you in comfort, we infuse each space with magic that captivates the senses.",
    },
    {
      head: "The Art Of Extraordinary Design",
      para: "Creating extraordinary interiors requires a delicate balance of artistry and functionality. Our team of designers possesses an innate ability to harmonize colors, textures, and patterns while ensuring that the design caters to your needs. We carefully curate every element of your living space, from exquisite furniture pieces and decor accents to lighting fixtures that set the perfect ambience. We aim to design a beautiful home and craft a masterpiece that tells your unique story.",
    },
    {
      head: "Sustainable Designs For The Future",
      para: "As the best interior designers in Chennai, we are committed to integrating sustainability into our design approach. We aim to design extraordinary works that offer a sustainable, environmentally friendly, aesthetically pleasing solution for our clients. Also, we give our best to deliver interiors that stand against time.",
    },
    {
      head: "Beyond Aesthetics - Functionality First",
      para: "While we are known for our extraordinary aesthetics, we never compromise on functionality. Our designers are adept at optimizing space, ensuring seamless flow, and maximizing utility in every corner of your home. We understand that a visually stunning interior is only genuinely extraordinary when it serves the needs of its occupants effortlessly. From clever storage solutions to ergonomic layouts, especially our Modular Kitchen Chennai designs prioritize convenience and ease of use, making your daily life more enjoyable and efficient.",
    },
    {
      head: "The Power of Attention to Detail",
      para: "The minor details in interior design can make the most significant difference. At Splash Interiors, our home interior designers in Chennai obsess over the minutest aspects of each project. Be it choosing the suitable fabrics for the living room or adding the final touch to elevate the space, we never let go of any chance and pursuit to complete the work with utmost perfection. Our passionate team of interior designers sets every detail apart to ensure that every space of your home receives the same attention, care and thoughtfulness.",
    },
    {
      head: "A Seamless Design Journey",
      para: "Embarking on an interior design project can be daunting, but the process is smooth and enjoyable with Splash Interiors. Our team takes the time to understand your vision, collaborate with you at every step, and provide transparent communication throughout the project's duration. With a keen eye for precision, we adhere to timelines and budgets, ensuring a stress-free and seamless design journey.If you seek exceptional residential interiors beyond the ordinary, Splash Interiors is your destination. Let us embark on a design adventure together, where creativity knows no bounds, resulting in a home that resonates with your soul. Experience the magic of extraordinary design with us – your trusted partner in crafting spaces that inspire comfort and delight. Contact us today, and let's make your dream home a reality.",
    },
  ];
  return (
    <div className="Rid">
      <Servicecomponent Data={RidData} ImageData={RidImageData} />
      <HeadData headData={headingsData} />
    </div>
  );
};
export default Rid;
