import React, { useState } from "react";
import testimonial1 from "../images/home/testimonial.png";
import testimonial2 from "../images/home/testimonial2.png";
import testimonial3 from "../images/home/testimonial3.png";
import testimonial4 from "../images/home/testimonial4.png";
import testimonial5 from "../images/home/testimonial5.png";
import rightarrow from "../images/home/rightarrowtest.svg";
import leftarrow from "../images/home/arrowleft-circle.svg";
import upcomma from "../images/home/upcomma.png";
import downcomma from "../images/home/downcomma.png";
import "../css/testimonialCarousel.scss";
const TestominialCarousel = () => {
  const testimonialcarouselData = [
    {
      coverimage: testimonial3,
      title:
        "Splash Interiors exceeded my expectations in transforming my living space. Their attention to detail and commitment to quality are truly commendable.",
      para: "Arjun, Coimbatore",
    },
    {
      coverimage: testimonial2,
      title:
        "I am thrilled with the results of my home renovation by Splash Interiors. Their expertise in creating functional yet stylish spaces is remarkable.",
      para: "Neha, Madurai",
    },
    {
      coverimage: testimonial3,
      title:
        "Splash Interiors provided exceptional service throughout the entire process. Their professionalism and dedication made the renovation project a breeze.",
      para: "Rahul, Salem",
    },
    {
      coverimage: testimonial4,
      title:
        "I couldn't be happier with the outcome of my home makeover by Splash Interiors. Their innovative ideas and efficient execution truly made a difference.",
      para: " Priyanka, Tirunelveli",
    },
    {
      coverimage: testimonial5,
      title:
        "Splash Interiors has transformed my house into a beautiful and inviting space. I am grateful for their expertise and creativity in bringing my vision to life.",
      para: "Kavitha, Tiruchirappalli",
    },
  ];
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const previousSlide = () => {
    const newIndex =
      (currentSlideIndex - 1 + testimonialcarouselData.length) %
      testimonialcarouselData.length;
    setCurrentSlideIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentSlideIndex + 1) % testimonialcarouselData.length;
    setCurrentSlideIndex(newIndex);
  };

  return (
    <div className="testimonialcarousel">
      <div
        class="coverimage-container"
        style={{
          backgroundImage: `url(${testimonialcarouselData[currentSlideIndex].coverimage})`,
        }}
      >
        {/* <img
          src={testimonialcarouselData[currentSlideIndex].coverimage}
          alt="hero"
          className="testimonialcover-image"
        /> */}
        <div
          class="testimonial-1"
          data-aos="zoom-out"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <button onClick={previousSlide} className="leftarrow">
            <img src={leftarrow} alt="left" className="arrowimg" />
          </button>
          <div class="testimonial1-in">
            <img src={upcomma} alt="hero" className="upcomma-image" />
            <div class="testimonial1-title">
              {testimonialcarouselData[currentSlideIndex].title}
            </div>
            <div class="testimonial1-para">
              {testimonialcarouselData[currentSlideIndex].para}
            </div>
            <div class="down-comma-container">
              <img src={downcomma} alt="hero" className="downcomma-image" />
            </div>
          </div>
          <button onClick={nextSlide} className="rightarrow">
            <img src={rightarrow} alt="right" className="arrowimg" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestominialCarousel;
