import React from "react";
import vir1 from "../images/home/vir1.png";
import vir2 from "../images/home/vir2.png";
import vir3 from "../images/home/vir3.png";
import vir4 from "../images/home/vir4.png";
import "../css/virtualdesign.scss";
const virtualDesComponent = () => {
  const virtualrData = [
    {
      image: vir1,
      title: "Contactless Experience",
      para: "No steeping out. Design your home interiors from the safety and comfort of your home",
    },
    {
      image: vir2,
      title: "Online Expertise",
      para: "Connect with our 1400+ designers virtually and explore designs online.",
    },
    {
      image: vir3,
      title: "Live 2D/3D Designs",
      para: "Explore life-like 3D designs online that are made for your floor plan",
    },
    {
      image: vir4,
      title: "Instant Pricing",
      para: "Enjoy complete price transparency and stay within budget.",
    },
  ];
  return (
    <div className="virtualDesComponent">
      {virtualrData.map((each, index) => (
        <div
          class="virtualDesComponentCard"
          data-aos="flip-left"
          data-aos-duration="1000"
          data-aos-delay={index * 200}
        >
          <img src={each.image} alt="about" className="virtualimg" />
          <div class="virTitle">{each.title}</div>
          <div class="virPara">{each.para}</div>
        </div>
      ))}
    </div>
  );
};
export default virtualDesComponent;
