import React from "react";
import dtcover from "../images/home/dtc.png";
import "../css/gallery.scss";
import Options from "../components/Options";
import Card2 from "../components/Card2";
const Gallery = () => {
  return (
    <div className="Gallery">
      <div class="Gallery-section1">
        <img src={dtcover} alt="hero" className="Gallerycover-image" />
        <div
          class="Gallery-section1-in"
          data-aos="zoom-out"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div class="Gallery-section1-title">Gallery</div>
        </div>
      </div>

      <Card2>
        <Options />
      </Card2>
    </div>
  );
};
export default Gallery;
