import React, { useEffect } from "react";
import Card2 from "./Card2";
import ServicesSlides from "./ServicesSlides";
import "../css/servicesComponent.scss";
import AOS from "aos";
import "aos/dist/aos.css";
const Servicecomponent = ({ Data, ImageData }) => {
  useEffect(() => {
    setTimeout(() => {
      AOS.refresh();
    }, 1000);
  }, []);
  return (
    <div className="cid">
      <div class="cid-1">
        <img src={Data.coverimage} alt="hero" className="cidcover-image" />
        <div
          class="cid1-in"
          data-aos="zoom-out"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div class="cid1-title">{Data.subtitle}</div>
        </div>
      </div>
      <Card2>
        <div class="cid-2">
          <div class="cidimgContainer">
            <img
              src={Data.image}
              alt="about"
              className="cidimg"
              data-aos="fade"
              data-aos-duration="2000"
              data-aos-delay="300"
            />
          </div>
          <div
            class="cid2-content"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            <div class="cid2title">{Data.title}</div>
            <div class="cid2para">{Data.para1}</div>
            <div class="cid2para">{Data.para2}</div>

            <ul>
              <li className="cidlist">{Data.li1}</li>
              <li className="cidlist">{Data.li2} </li>
              <li className="cidlist"> {Data.li3}</li>
              <li className="cidlist">{Data.li4}</li>
            </ul>
          </div>
        </div>
      </Card2>
      <Card2>
        <ServicesSlides Data={ImageData} />
      </Card2>

      <Card2>
        <div
          class="cid-4"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          {Data.para5 && <div className="cid-para">{Data.para5}</div>}
          {Data.para6 && <div className="cid-para">{Data.para6}</div>}
          {Data.para7 && <div className="cid-para">{Data.para7}</div>}
          {Data.para8 && <div className="cid-para">{Data.para8}</div>}
          {Data.downhead && <div className="cid4-title">{Data.downhead}</div>}
        </div>
      </Card2>
    </div>
  );
};
export default Servicecomponent;
