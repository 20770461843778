import React from "react";
import dtcover from "../images/home/dtc.png";
import "../css/contact.scss";
import mappin from "../images/service/mapPin.svg";
import mailIcon from "../images/service/mailIcon.svg";
import phonee from "../images/service/phonee.svg";
import Card2 from "../components/Card2";
import Form from "../components/form";
import Map from "../components/maps";
// import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const Contact = () => {
  const contactData = [
    {
      icon: mappin,
      para1:
        "No. 10 & 23, Second floor, Bazaar Main Road,Ram nagar, Madipakkam,",
      para2: "Chennai, Tamil Nadu 600091",
    },
    {
      icon: phonee,
      para1: "+91 44 4785 0035",
      para2: "",
    },
    {
      icon: mailIcon,
      para1: "mailus@foxibe.com",
      para2: "",
    },
  ];

  return (
    <div className="contact">
      <div class="contact-section1">
        <img src={dtcover} alt="hero" className="contactcover-image" />
        <div
          class="contact-section1-in"
          data-aos="zoom-out"
          data-aos-duration="1000"
          data-aos-delay="300"
        >
          <div class="contact-section1-title">Contact</div>
        </div>
      </div>

      <Card2>
        <div class="contact-section2-container">
          {contactData.map((each, index) => (
            <div
              class="contact-section2-card"
              key={index}
              data-aos="fade"
              data-aos-duration="1500"
              data-aos-delay={index * 500}
            >
              <div class="contact-icon-container">
                <img src={each.icon} alt="hero" className="contacticon" />
              </div>
              <div class="cspara-container">
                <div class="cspara">{each.para1}</div>
                <div class="cspara">{each.para2}</div>
              </div>
            </div>
          ))}
        </div>
      </Card2>
      <div class="formcontainer">
        <Form />
      </div>

      <Card2>
        <div
          class="maplocation"
          // data-aos="zoom-out"
          // data-aos-duration="1000"
          // data-aos-delay="300"
        >
          {/* <img src={map} alt="hero" className="mapImage" /> */}
          <Map />
        </div>
      </Card2>
    </div>
  );
};
export default Contact;
